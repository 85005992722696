<template>
  <div class="ChoosePanel__main-container">
    <div class="ChoosePanel__center-container">
      <img src="@/assets/img/kc-kp-logo.svg" class="ChoosePanel__top-logo" />
      <button class="ChoosePanel__button-blue" @click="newPanel()">
        New Admin Panel
      </button>
      <a :href="oldAdminLink" class="ChoosePanel__button-blue"
        >Old Admin Panel</a
      >
      <div class="ChoosePanel__line" />
      <img src="@/assets/img/kpgs-logo.svg" class="ChoosePanel__bottom-logo" />
      <a :href="kpayLink" class="ChoosePanel__button-yellow"
        >KingsPay Goods & Services</a
      >
    </div>
  </div>
</template>

<script>
import { REMOTE_PATHS } from '@/constants/remote-paths.constant';

export default {
  data() {
    return {
      oldAdminLink: REMOTE_PATHS.OLD_ADMIN_LINK,
      kpayLink: REMOTE_PATHS.KPAY_GOODS_SERVICES,
    };
  },
  methods: {
    newPanel() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/choose-panel/index.scss';
</style>
